import Hero from "../../Classes/Hero";
import { gsap, SplitText } from "gsap/all";
gsap.registerPlugin(SplitText)

export default class Contact extends Hero {
    constructor () {
        super({
            id: 'hero-contact',
            heroClassname: '.heroContact',
            heroItems: {
                breadcrumb: '.parent_breadcrumb',
                subtitle: '.hero_subtitle',
                title: '.hs__title',
                image: '.full-width-hero-image img',
                button: '.HeroFullWidth .contact-button-group .arrow-btn',
                imageArea: '.full-width-hero-image',
                outerMask: '.hero-logo-svg',
                wave: '.wave-container',
                contactItems: '.contact-h-item'
            }
        })
    }

    create ({scroll, container}){
        super.create()
        this.scroll = scroll
        this.container = container

        this.title =  new SplitText(this.heroItems.title, {type: "lines", linesClass: "lineChild"});
        this.innerTitle =  new SplitText(this.heroItems.title, {type: "lines", linesClass: "lineParent"});
        this.subtitle =  new SplitText(this.heroItems.subtitle, {type: "lines", linesClass: "lineChild"});
        this.innerSubtitle =  new SplitText(this.heroItems.subtitle, {type: "lines", linesClass: "lineParent"});

        // hide all elements
        gsap.set([this.title.lines, this.subtitle.lines, this.heroItems.button], { yPercent: 150, rotate:15, transformOrigin: '0 50%'})

        if (this.heroItems.breadcrumb) {
            gsap.set([this.heroItems.breadcrumb], { autoAlpha:0, yPercent: 20 })
        }
   
        gsap.set([this.heroItems.wave, this.heroItems.contactItems], { autoAlpha:0,  yPercent: 40 })

        // split text        
        this.init()
        this.onResize()
    }


    onLoad() {
        this.tlHero = gsap.timeline({
            defaults: {
                ease: "expo",
                duration: 0.4,
           }
        })
        this.tlHero
          //  .to(this.heroItems.breadcrumb, {delay: 0.3, autoAlpha: 1, yPercent: 0, duration:1 })
            .to([this.title.lines], { yPercent: 0, stagger:0.05, rotate: 0, duration:0.8}, 0)
            .to([this.subtitle.lines], { yPercent: 0, stagger:0.2, rotate: 0, duration: 0.8}, '-=100%')
            .to([this.heroItems.contactItems, this.heroItems.button], {autoAlpha: 1, rotate: 0, yPercent: 0, stagger:0.1},'-=50%')
            .to(this.heroItems.wave, {autoAlpha: 1, yPercent: 0, duration:1.2},'-=50%')
    }

    onResize () {
    }

    bubbleIn() {
        gsap
        .timeline({defaults: {duration: 1, ease: 'expo'}})
        .to(this.heroItems.outerMask, {scale: 0.95})
        .to(this.heroItems.image, {scale: 1.1}, 0)
    }

    bubbleOut() {
        gsap
        .timeline({defaults: {duration: 1, ease: 'expo'}})
        .to([this.heroItems.outerMask, this.heroItems.image], {scale: 1})
    }

    scrollTo() {
        const height =  this.element.getBoundingClientRect().height
         this.scroll.scrollTo(height, {'easing': [0.25, 0.00, 0.35, 1.00],})
     }

    eventListeners() {
        this.heroItems.imageArea.addEventListener('mouseenter', this.bubbleIn.bind(this))
        this.heroItems.imageArea.addEventListener('mouseleave', this.bubbleOut.bind(this))
        if (this.heroItems.button) {
            this.heroItems.button.addEventListener('click', this.scrollTo.bind(this))
        }
    }

    init() {
       this.eventListeners()
       console.log(this)
    }
}