import Block from "../../Classes/Block";
import { gsap, ScrollTrigger, DrawSVGPlugin, SplitText } from "gsap/all";
gsap.registerPlugin( ScrollTrigger, DrawSVGPlugin, SplitText)


export default class HalfHalfSection extends Block {
    constructor () {
        super({
            id: 'half-half-title-section',
            blockClassname: '.half-half-title-section',
            blockItems: {
                sections: '.half-column',
                imagesBlocks: '.rg__image',
                images: '.rg__image img',
                masks: '.rg__image--mask'
            }
        })
    }

    create ({ scroll, container}) {
        super.create()
        this.scroll = scroll
        this.container = container

        gsap.set(this.blockItems.imagesBlocks, {yPercent: -101})
        gsap.set(this.blockItems.masks, {yPercent: 101})
        gsap.set(this.blockItems.images, {scale: 1.2})

        this.init()
        this.onResize()

    }
    createHoverReveal(event) {
        const imageBlock = event.target.querySelector('.rg__image')
        const image = event.target.querySelector('.rg__image img')
        const mask = event.target.querySelector('.rg__image--mask')

        let timeline = gsap.timeline({
            defaults: {
                duration: 0.7,
                ease: 'power4.out'
            }
        })

        if (event.type === 'mouseenter') {

            timeline
                .to([mask, imageBlock], {yPercent: 0})
                .to(image, {duration: 1.1, scale: 1 }, 0)

        } else if (event.type === 'mouseleave') {

            // back to intial positions

            timeline
                .to([mask], {yPercent: 100})
                .to([imageBlock], {yPercent: -101}, 0)
                .to(image, {duration: 1.1, scale: 1.2 }, 0)
        }

        return timeline
    }

    onResize() {
        const mql = window.matchMedia('(max-width: 992px)')

        if (mql.matches) {
            /* The viewport is less than, or equal to, 700 pixels wide */
            console.log('mobile')
          //  gsap.set([this.blockItems.imagesBlocks, this.blockItems.masks, this.blockItems.images], {clearProps: 'all'})
            this.blockItems.sections.forEach( section => {
                section.removeEventListener('mouseenter', this.hoverReveal)
                section.removeEventListener('mouseleave', this.hoverReveal)
            })
        } else {
            /* The viewport is greater than 700 pixels wide */
            console.log('desktop')

            this.blockItems.sections.forEach( section => {
                section.addEventListener('mouseenter', this.hoverReveal)
                section.addEventListener('mouseleave', this.hoverReveal)
            })
        }
    }

    eventListeners() {
        // Add Event Listeners to each section
        // this.blockItems.sections.forEach( section => {
        //     section.addEventListener('mouseenter', this.createHoverReveal.bind(this))
        //     section.addEventListener('mouseleave', this.createHoverReveal.bind(this))
        // })


        window.addEventListener('resize', this.onResize.bind(this))
    }
  

    init() {
        // save the click handler so it can be used in multiple places
        this.hoverReveal = this.createHoverReveal.bind(this);

        this.eventListeners()
    }

}