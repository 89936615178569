import Block from "../../Classes/Block";
// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

export default class Testimonials extends Block {
    constructor () {

        super({
            id: 'testimonial',
            blockClassname: '.testimonial',
            blockItems: {
                slider: '.ss-container',
                items: '.testimonial-slide',
                quotes: '.testimonial-short',
                profileImage: '.profile-image',
                names: '.author',
                jobTitle: '.job-title',
            }
        })
    }

    create ({ scroll, container}) {
        super.create()
        this.init()

        this.scroll = scroll
        this.container = container
    }

    swiperInit () {
        this.swiper = new Swiper(this.blockItems.slider, {
          slidesPerView: 1,
          spaceBetween: 30,
          simulateTouch: true,
          effect: 'fade',
          loop: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          },
          fadeEffect: {
            crossFade: true
          },
          autoplay: {
            delay: 8000,
            disableOnInteraction: false
          },
          });
    }

  init () {
  this.swiperInit()
}


}

