import Block from "../../Classes/Block";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)
import Scroll from "../../Components/Scroll";


export default class DiscoveryCircles extends Block {
    constructor () {

        super({
            id: 'info-circles',
            blockClassname: '.discovery-circles',
            blockItems: {
                circle: '.discovery-circle',
                stages: '.circle-desc-section',
                circleTexts: '.dc__text h6',
                stageTitles: '.circle-desc-title',
                stageDesc: '.circle-desc-desc'

            }
        })

        this.firstElem = document.querySelector(".circle-desc-section");
    }

    create ({ scroll, container}) {
        super.create()
        this.init()

        gsap.set([this.blockItems.stageTitles, this.blockItems.stageDesc], {autoAlpha:0, yPercent:20 })

        this.scroll = scroll
        this.container = container

    }

    pin () {
        ScrollTrigger.create({
            trigger: this.blockItems.circle,
            start: "center center",
            endTrigger: this.blockItems.stages[this.blockItems.stages.length - 1],
            end: "center center",
            scroller: this.container,
            pin: true,
            marker: true
        })

    }

    goToSection(index, anim) {
        console.log('running')
          this.scroll.scroll.scrollTo(this.blockItems.stages[index], {offset: -(this.blockItems.stages[index].offsetHeight / 1.5)})
          if(anim) {
            anim.restart();
          }
    }


    animateStages () {

        this.blockItems.stages.forEach((stage, index) => {

           const title = stage.querySelector('.circle-desc-title')
           const desc = stage.querySelector('.circle-desc-desc')
     
            //add active class
            ScrollTrigger.create({
                trigger: stage,
                start: 'top center',
              //  markers: true,
                end: 'bottom center',
               // onToggle: self => console.log("toggled, isActive:", self.isActive),
                onEnter: self => {
               //   console.log(self, stage, index);

                 // this.goToSection(index)
                 

                     gsap.to([title, desc], { autoAlpha: 1,  ease: "Power4.easeOut.easeOut", duration: 1, yPercent:0 })

                    this.blockItems.circleTexts.forEach(text => {
                        text.classList.remove('active-circle-text')
                    })
                    this.blockItems.circleTexts[index].classList.add('active-circle-text')

                  if (index === 1) {
                      this.blockItems.circle.classList.add('pulsing-ui')
                      gsap.to(this.blockItems.circle, { scale: 1.2})
                  } else if (index === 2 ) {
                    this.blockItems.circle.classList.add('big-circle')
                    gsap.to(this.blockItems.circle, { scale: 1.8})
                  } else {

                  }
                },

                onLeave: self => {

                    if (index === 1) {
                        this.blockItems.circle.classList.remove('pulsing-ui')
                        gsap.to(this.blockItems.circle, { scale: 1})
                    } else if (index === 2 ) {
                       // this.blockItems.circle.classList.remove('big-circle')
                       // gsap.to(this.blockItems.circle, { scale: 1})
                    }

                },
                onLeaveBack: self => {

                    if (index === 1) {
                        this.blockItems.circle.classList.remove('pulsing-ui')
                        gsap.to(this.blockItems.circle, { scale: 1})
                    } else if (index === 2 ) {
                        this.blockItems.circle.classList.remove('big-circle')
                        gsap.to(this.blockItems.circle, { scale: 1})
                    }

                },
                onEnterBack: self => {
                  //  console.log(self, stage, index);

               //   this.goToSection(index)

                  this.blockItems.circleTexts.forEach(text => {
                    text.classList.remove('active-circle-text')
                })
                this.blockItems.circleTexts[index].classList.add('active-circle-text')
              

                    if (index === 1) {
                        this.blockItems.circle.classList.add('pulsing-ui')
                        gsap.to(this.blockItems.circle, { scale: 1.2})
                    } else if (index === 2 ) {
                        this.blockItems.circle.classList.add('big-circle')
                        gsap.to(this.blockItems.circle, { scale: 1.8})
                    }
                  },
                // end: () =>`+=${this.blockItems.stages[this.blockItems.stages.length - 1].offsetHeight / 2}`,
              //  markers: true,
                // toggleClass: {
                //     targets: stage[index],
                //     className: "js-tab-visible",
                // }
            })


            
        })

    }

    init() {
        this.pin()
        this.animateStages()
    }


}

