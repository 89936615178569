import Block from "../../Classes/Block";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)
import Scroll from "../../Components/Scroll";


export default class InfoCircles extends Block {
    constructor () {

        super({
            id: 'info-circle-list-section',
            blockClassname: '.info-circle-list-section',
            blockItems: {
                circleSection: '.ifl_circle-section',
                circleLeft: '.ifl_circle_left',
                circleRight: '.ifl_circle_right',
                circleMiddle: '.ifl_circle_middle',
                circleContent: '.ifl_circle_content'
            }
        })

    }

    create ({ scroll, container}) {
        super.create()
        this.init()

        this.scroll = scroll
        this.container = container

        gsap.set(this.blockItems.circleLeft, {left:'0%'})
        gsap.set(this.blockItems.circleRight, {right:'0%'})
    }

    animateCircles () {
        this.animationIn = gsap.timeline({
            defaults: {
              //  duration: 0.6,
                ease: 'expo.out'
            },
            // add scrolltrigger to timelinex
            scrollTrigger:{
                trigger: this.blockItems.circleSection,
                start:"top center",
                end:"bottom center",
                toggleActions:"play pause restart reset",
              //  markers: true,
                scrub: true
            }
        })
        this.animationIn
            .to(this.blockItems.circleLeft, { left:'50%', borderColor: '#737D9B'}, 0)
            .to(this.blockItems.circleRight, {right:'50%', borderColor: '#737D9B'}, 0)
            .to(this.blockItems.circleMiddle, {backgroundColor: '#737D9B'})
            .to(this.blockItems.circleContent, {color: 'white'}, '-=100%')
    }

    init() {
        this.animateCircles()
    }


}

