import Block from "../../Classes/Block";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)


export default class ExecCoaching extends Block {
    constructor () {

        super({
            id: 'exec-coaching-section',
            blockClassname: '.exec-coaching-section',
            blockItems: {
                images: '.exec-images img'
            }
        })
    }

    create ({ scroll, container}) {
        super.create()


        this.scroll = scroll
        this.container = container

        this.evenImages = []
        this.oddImages = []

        
        
        this.blockItems.images.forEach((element, index, array) => {
            // if (index % 2 === 0) {
            //     // get even index of images
            //     this.evenImages.push(element)

            // } else {
            //     // get odd index of images
            //     this.oddImages.push(element)
            // }

            // get first and last array items
            if (index === 0 || index === array.length - 1 ) {
                this.evenImages.push(element) 
            } else {
                this.oddImages.push(element)
            }
        });

       

        this.init()
      

    }

    animate() {

        console.log(this.oddImages, this.evenImages)

        gsap.to(this.evenImages, { 
            yPercent: -20,
            ease: "none",
          //  scale: 1.2,
          
            scrollTrigger: {
              trigger: this.blockClassname,
              start: "top center",
              end: "bottom center",
              toggleActions:"play pause reverse resume",
              scrub: true
            }
        })


        gsap.to(this.oddImages, { 
            yPercent: 20,
            ease: "none",
          //  scale: 1.2,
            
            scrollTrigger: {
                trigger: this.blockClassname,
                start: "top center",
                end: "bottom center",
                toggleActions:"play pause reverse resume",
                scrub: true
            }
        })



        // this.tlBlock = gsap.timeline({
        //     defaults: {
        //         ease: "expo.out",
        //         duration: 0.8,
        //         scrollTrigger: {
        //             trigger: this.blockClassname,
        //             start: "top center",
        //             end: "center center",
        //             markers: true,
        //             scrub: true
        //           }
        //         }
                
        // })

        // this.tlBlock
        //     .to(this.blockClassname, { backgroundColor: 'black'}, 0)

    }

        onResize () { }
  


    init() {
        this.onResize()
        this.animate()


        }
}