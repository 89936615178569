import Component from "../Classes/Component"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)

export default class Header extends Component {
    constructor ({ scroll, container}) {
        super({
            element: 'header',
            elements: {
                toggleIcon: '.menu_toggle',
                mobileMenu: '.mobile-sidebar',
                pageBackground : '.page-cover',
                page : '.page-in-full',
                fullScreenNav : ".fullscreen-nav-js",
                revealActive: ".header__activate",
                progressBar: '.header__progress--inner',
                menuItem: '.menu-item-has-children a:not(.sub-menu a)',
                subMenus: '.sub-menu'
            }
        })

        this.screenLarge = window.matchMedia("(min-width: 1440px)")

        // this.scrollUp = "js-scroll-up";
        // this.scrollDown = "js-scroll-down";
        // this.lastScroll = 0;

        this.scroll = scroll
        this.container = container

        this.init()
    } 

    init (scroll) {

      super.create()
      this.smoothScroll = scroll

      //  this.choosePreloader()
      this.eventListeners(this.scroll)
      


    }

    eventListeners(scroll) {
      this.smoothScroll = scroll
    //  window.addEventListener('scroll', this.scrolling.bind(this))
      this.elements.toggleIcon.addEventListener('click', this.toggleMenu.bind(this));
      window.addEventListener('resize', this.removeCover.bind(this))
      this.elements.menuItem.forEach(element => {
        element.addEventListener('click', this.toggleSubMenu.bind(this))
      });

    }

    removeCover() {
      if (this.screenLarge.matches) {
        this.elements.pageBackground.classList.remove('page-cover-opacity');
      }
      
    }

    toggleSubMenu(event) {
      event.preventDefault()
      const currentSubMenu = event.target.parentNode.querySelector('.sub-menu')

           // close all other subMenus
           this.elements.subMenus.forEach(menu => {
            menu.classList.remove('active-menu');
          })
    
          //show or hide each menu 
          if (currentSubMenu.classList.contains('open-menu')) {
            currentSubMenu.classList.remove('active-menu')
            currentSubMenu.classList.remove('open-menu')
            console.log(currentSubMenu, this.elements.subMenus)
          } else {

            // remove all open menus
            this.elements.subMenus.forEach(menu => {
              menu.classList.remove('open-menu');
            })

            currentSubMenu.classList.add('active-menu')
            currentSubMenu.classList.add('open-menu')
            console.log(currentSubMenu, this.elements.subMenus)
          }

  
    }

    toggleMenu() {
        this.elements.toggleIcon.classList.toggle('toggle_on');

         // IF we have logo - left header selected
         if (this.elements.mobileMenu) {
          this.elements.mobileMenu.classList.toggle('mobile-menu-appear');
          this.elements.pageBackground.classList.toggle('page-cover-opacity');
          this.elements.page.classList.toggle('paged');
          }
  
          // IF we have logo middle selected
  
          if (this.elements.fullScreenNav) {
            this.elements.fullScreenNav.classList.toggle("opacity-full");
          }

    }


    isScrolling (obj) {

      ScrollTrigger.update()

      // update progress

      //   console.log(obj.currentElements)
      this.newScroll.current = obj.scroll.x;
   //   const distance = this.newScroll.current - this.newScroll.cache;
      this.newScroll.cache = this.newScroll.current;

      
    //  console.log('how muc we scrolled ' + obj.scroll.y + 'how much too scroll' + obj.limit.y)


      //update progress bar with width using gsap utility
      // map range from pixels to 100
      let widthToProgress = gsap.utils.mapRange(0, obj.limit.y, 0, 100)
      let howMuchScrolled = widthToProgress(obj.scroll.y)
     // console.log(howMuchScrolled)

      this.header.elements.progressBar.style.width = `${howMuchScrolled}%`

  
    }

}