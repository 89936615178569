
import locomotiveScroll from 'locomotive-scroll'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)


export default class Scroll {
    constructor ({ container }) {

        this.scrollContainer = container

        this.scroll = new locomotiveScroll({
            el: this.scrollContainer,
            smooth: true,
            getDirection: true,
            direction: 'vertical',
            reloadOnContextChange: 'true'
        })

        this.init()
    }



    scrollTo (target, options) {
        return this.scroll.scrollTo(target, { options })
    }

    init () {

        ScrollTrigger.scrollerProxy(this.scrollContainer, {
            scrollTop: (value) => {
                return arguments.length ? this.scroll.scrollTo(value, 0, 0) :    this.scroll.scroll.instance.scroll.y;
            },

            getBoundingClientRect() {
                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            },

            // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
            pinType: this.scrollContainer.style.transform ? "transform" : "fixed"
              
        });

    
        ScrollTrigger.defaults({
            scroller: this.scrollContainer
        })

        ScrollTrigger.addEventListener("refresh", () => this.scroll.update());

        // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
        ScrollTrigger.refresh();

    }

    refresh() {
        ScrollTrigger.addEventListener("refresh", () => this.scroll.update());

        console.log('refresh')

        ScrollTrigger.refresh(true);

    }


}