import Component from "../Classes/Component"
import { gsap, SplitText } from "gsap/all";
gsap.registerPlugin(SplitText)

export default class Preloader extends Component {
    constructor ( ) {
        super({
            element: '.pre_loader',
            elements: {
                title: '.pl--title',
                logo : '.pl--icon img',
                tagline: '.pl--tagline'
            }
        })


       this.init()
    } 

    handleMyEvent(data)  {
        console.log(`Was fired: ${data}`);
    }

    init() {
      //  gsap.set(this.elements.logo, {autoAlpha:0})
        this.title =  new SplitText(this.elements.title, {type: "lines", linesClass: "lineChild"});
        this.innerTitle =  new SplitText(this.elements.title, {type: "lines", linesClass: "lineParent"});
        this.tagline =  new SplitText(this.elements.tagline, {type: "lines", linesClass: "lineChild"});
        this.innerTagline =  new SplitText(this.elements.tagline, {type: "lines", linesClass: "lineParent"});

        this.onLoaded()
    }

    onLoaded() {
        return new Promise(resolve => {
            this.on('completed', this.handleMyEvent.bind(this))
         
            this.tlPreloader = gsap.timeline({
                ease: 'expo.out',
                delay:1
            })

            // put gsap here
            this.tlPreloader
                .to(this.elements.logo, { autoAlpha:0, duration: 1.2})
                .to([this.title.lines, this.tagline.lines], {duration:1.2, stagger:0.1, yPercent:100}, 0)
                .to(this.element, { autoAlpha:0, duration: 0.8})

           this.tlPreloader.call(() => {
            this.emit('completed', 'yes')
            })

        })

    }

    destroy() {
    this.element.parentNode.removeChild(this.element);
    }
}



