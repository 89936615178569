import Hero from "../../Classes/Hero";
import { gsap, SplitText, ScrollTrigger } from "gsap/all";
gsap.registerPlugin( SplitText, ScrollTrigger)

export default class heroScroll extends Hero {
    constructor () {
        super({
            id: 'heroScroll',
            heroClassname: '.heroScroll',
            heroItems: {
                logo: '.hs__svg svg path',
                subtitle: '.hs__subtitle',
                title: '.hs__title--black',
                video: '.hs__video-group',
                textGroup: '.hs__scroll-text',
                textMask: '.hs__title--mask',
                whiteTitle: '.hs__title-white',
                desc: '.hs__desc',
                scrollText: '.hs__scroll_text',
                scrollDown: '.hs__scroll__down',
                video: '.video-hero-image.hs__video-group',
                twoButtons: '.two-buttons',
                tagline: '.hs_tagline',
                videoMask: '.video-masked',
                scrollLine: '.hs__scroll-text-bottom'
            }
        })

    }

    create (){
        super.create()
        console.log('time to run a hero')

        this.set()
     //   gsap.set([ this.heroItems.button, this.heroItems.subtitle, this.heroItems.desc], {opacity: 0})

        // get position of title on page

        // split text   
      //  this.setupValues()     
        this.init()
      
    }

    set() {
        gsap.set(this.heroItems.logo, {autoAlpha:0})
        gsap.set([this.heroItems.twoButtons, this.heroItems.tagline], {autoAlpha:0, yPercent:20})
        gsap.set(this.heroItems.textMask, { yPercent: 100 })
        gsap.set(this.heroItems.whiteTitle, { yPercent: -100 })
        gsap.set(this.heroItems.scrollLine, {width:0})
        gsap.set([this.heroItems.desc, this.heroItems.scrollText, this.heroItems.subtitle, this.heroItems.title], {autoAlpha: 0})
    }


    animate() {

        //First timeline for zooming in video

        this.tlHero = gsap.timeline({
            defaults: {
                ease: "expo.out",
                duration: 0.8,
                scrollTrigger: {
                    trigger: this.heroItems.video,
                    start: "top center",
                    end: "center center",
                   //  markers: true,
                    scrub: true
                  }
                }
                
        })

        this.tlHero
            .to(this.heroItems.video, { maxWidth: '100%'}, 0)
            .to([this.heroItems.tagline, this.heroItems.twoButtons, this.heroItems.desc], {autoAlpha:1, yPercent:0})


            // Second Timeline turn text white on Scroll
            this.tlText = gsap.timeline({
                defaults: {
                    ease: "none",
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: this.heroItems.video,
                      //  start: `top top+=${this.textBottom}`,
                        start: `top top+=${this.valueBottom(this.heroItems.title)}`,
                       // end: `top top+=${this.textTop}`,
                        end: `top top+=${this.valueTop(this.heroItems.title)}`,
                      //  markers: true,
                        scrub: true
                      }
                    }
                    
            })

            this.tlText
                .to(this.heroItems.textMask, {yPercent: 0}, 0)
                .to(this.heroItems.whiteTitle, {yPercent: 0}, 0)
                .to(this.heroItems.scrollText, {
                    autoAlpha: 0,
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: this.heroItems.video,
                        start: `top top+=${this.valueBottom(this.heroItems.scrollText)}`,
                        end: `top top+=${this.valueTop(this.heroItems.scrollText)} `,
                        toggleActions:"play pause restart reset",
                        scrub: true
                      //  markers: true
                    }
                })
                .to(this.heroItems.subtitle, {
                    autoAlpha: 0,
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: this.heroItems.video,
                        start: `top top+=${this.valueBottom(this.heroItems.subtitle)}`,
                        end: `top top+=${this.valueTop(this.heroItems.subtitle)}`,
                        toggleActions:"play pause restart reset",
                      //  markers: true,
                        scrub: true
                    }
                })
           // .to(this.element, {backgroundColor: 'rgb(93 93 93)'}, 0)

    }

    fixInPlace () {

         ScrollTrigger.create({
             trigger: this.heroItems.textGroup,
             start: "center center",
             endTrigger: this.heroItems.video,
             end: "center center",
             pin: true,
           //  markers: true
     })
 
 }


 valueBottom(element) {
     return element.getBoundingClientRect().bottom
 }

 valueTop(element) {
    return element.getBoundingClientRect().top
}

    onResize () {
        
     //   this.setupValues()
        super.onResize()

        if (this.tlText) {
            // if we have animations that are in the middle of their tween when we resize the screen, gsap inline styles will remain
            // this code kills these tweens
        //     this.tlText.killTweensOf('*')
        //     this.tlHero.killTweensOf('*')
        //  //   this.tlText.set([this.heroItems.textMask, this.heroItems.whiteTitle, this.heroItems.scrollText, this.heroItems.subtitle, this.heroItems.video, this.heroItems.tagline ], {clearProps:"all"})

  
         this.tlText.invalidate()
         this.tlText.restart()
         console.log(this.tlText.vars.defaults.scrollTrigger, this.tlText)
         
        //     this.animate()      
        }
    }

    onLoad() {
        console.log('running after preloader')
        this.homeScrollonLoad = gsap.timeline({
            ease: 'expo.out',
        })

        this.homeScrollonLoad
            .to([this.heroItems.logo, this.heroItems.title, this.heroItems.subtitle, this.heroItems.scrollText], {autoAlpha: 1, stagger:0.1, duration:0.6})
            .to(this.heroItems.videoMask,{width: '0%', duration:0.9}, 0)
            .to(this.heroItems.scrollLine, {width:'100%'})

        // call 
        this.homeScrollonLoad.call(this.animate.bind(this))
        
    }


    init() {
       this.fixInPlace()
    }

}