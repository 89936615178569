import Block from "../../Classes/Block";
import { gsap, ScrollTrigger, SplitText } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, SplitText)
// import { isInViewport } from "../../Utils";


export default class Quote extends Block {
    constructor () {
        super({
            id: 'quote-section',
            blockClassname: '.quote-section',
            blockItems: {
                quote: '.quote-text-quote',
                logo: '.bg-logo',
                quotationLeft: '.quotation-left',
                quotationRight: '.quotation-right',
                author: '.author h4'
            }
        })
    }

    create ({ scroll, container}) {
        super.create()

        this.quote =  new SplitText(this.blockItems.quote, {type: "lines", linesClass: "lineChild"});
        this.innerQuote =  new SplitText(this.blockItems.quote, {type: "lines", linesClass: "lineParent"});

        // hide all elements
        gsap.set([this.quote.lines, this.blockItems.author], { yPercent: 150, rotate:15, transformOrigin: '0 50%'})
        gsap.set([this.blockItems.quotationLeft, this.blockItems.quotationRight], { autoAlpha:0,  yPercent: 40 })
        gsap.set(this.blockItems.logo, { autoAlpha:0})
        
        this.init()

        this.scroll = scroll
        this.container = container


        console.log(this)
    }

    animate() {

        this.blockClassname.forEach(block=> {

            this.quoteAnim = gsap.timeline({
                defaults: {
                    ease: "expo",
                    duration: 0.4,
               },
                // add scrolltrigger to timelinex
                scrollTrigger:{
                trigger: block,
                start:"top+=20% bottom",
                end:"bottom bottom",
               // toggleActions:"play pause restart reset",
               // markers: true,
                scrub: true
            }
            })

            const lines = block.querySelectorAll('.quote-text-quote .lineChild')
            const quotations = [...block.querySelectorAll('.quotation')]
            const logo = block.querySelector('.bg-logo')
            const author = block.querySelector('.author h4')
    
            this.quoteAnim
                .to(lines, { yPercent: 0, stagger:0.05, rotate: 0, duration:0.8}, 0)
                .to(quotations, {autoAlpha: 1, yPercent: 0}, 0)
                .to(logo, {autoAlpha: 1, duration:1.2 },'-=50%')
                .to(author, { yPercent: 0, stagger:0.05, rotate: 0, duration:0.8},'-=100%')
        })
    }


    init () {
        this.animate()
    }


}

