import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import _ from 'lodash'
gsap.registerPlugin(ScrollTrigger)


import Header from 'components/Header'
import Preloader from 'components/Preloader'
import Scroll from 'components/Scroll'

//Blocks
import ExecCoaching from 'blocks/page/Exec-Coaching'
import Quote from 'blocks/page/Quote'
import Testimonials from 'blocks/page/Testimonials'
import DiscoveryCircles from 'blocks/page/Discovery-circles'
import InfoCircles from 'blocks/page/Info-Circles'
import HalfHalfSection from 'blocks/page/Half-Half'
// import Welcome from 'blocks/page/Welcome'
// import PortfolioBlock from 'blocks/page/Portfolio'
// import Gallery from 'blocks/page/Gallery'
// import Parallax from 'blocks/page/Parallax'

// Hero
import heroScroll from "Blocks/Hero/Hero-scroll";
import FullWidth from "Blocks/Hero/Full-width";
import Contact from "Blocks/Hero/Contact";

//Pages
// import Portfolio from 'pages/Our-Portfolio'

class App {
    constructor () {
        this.createContent()
        this.createHero()
        this.createPreloader()
        this.createBlocks()
        this.addEventListeners()
        this.onResize()
       
    }

    createContent() {
        this.scrollWrapper = document.querySelector('.locomotive-scroll-container')
        this.smoothScroll = new Scroll({ container: this.scrollWrapper })
        this.header = new Header({  scroll: this.smoothScroll, container: this.scrollWrapper })

        this.preloaderInView = document.querySelector('.pre_loader')
        this.body = document.querySelector('body')
        this.newScroll = {cache: 0, current: 0}
    }

    createPreloader () {
        if (this.preloaderInView ) {
            this.preloader = new Preloader()
         //   console.log(this.preloader._events)
            this.preloader.once('completed', this.onPreloaded.bind(this))
        } else {
            this.theHero.onLoad()
        }
    }


    onPreloaded () {
        console.log('run onplreloadered')
        this.preloader.destroy()
        // run hero animations
        this.theHero.onLoad()
    }

    onResize () {
        // if (this.theHero) {
        //     this.theHero.onResize()
        // }
        // this.createContent()
        // this.createHero()
        // this.createPreloader()
        // this.createBlocks()
      }

    createHero() {
        // only one hero per page so don't have to check for activating multiple heros
        this.heros = {
          //  boxed: new Boxed(),
              Contact: new Contact(),
              heroScroll: new heroScroll(),
              HeroFullWidth: new FullWidth()
        }
        this.currentHero = document.querySelector('[data-hero]')

        if (this.currentHero) {
            this.currentHeroValue = this.currentHero.getAttribute('data-hero')
            const selectors = Object.keys(this.heros); //get keys from object as an array
          console.log('hello', this.currentHeroValue, selectors)
            selectors.forEach((hero) => {
            if (this.currentHeroValue.includes(hero) ) {
              //  console.log(this.heros[hero])
                this.theHero = this.heros[hero]
                this.theHero.create({  scroll: this.smoothScroll, container: this.scrollWrapper })
            } 
            // else {
                // console.log('no matches')
            // }
        })
     }
    }


    createBlocks() {
        // add all gutenberg blocks with js
        this.blocks = {
            ExecCoaching: new ExecCoaching(),
            Quote: new Quote(),
            testimonials: new Testimonials(),
            DiscoveryCircles: new DiscoveryCircles(),
            InfoCircles: new InfoCircles(),
            HalfHalfSection: new HalfHalfSection()
        }
        
        // check how many blocks are on page load
        this.dataBlocks = [...document.querySelectorAll('[data-block]')]
        this.blocksOnPage = []
       
        // check each blocks on page and grab their classname
        this.dataBlocks.forEach(entry => {
            const classname = '.' + entry.getAttribute('data-block')
            this.blocksOnPage.push(classname)
        })

     //  console.log(this.blocks)
        // create array of blocks functions
        for ( const [block, value] of Object.entries(this.blocks)) {

   //  console.log(this.blocksOnPage, value.selector)
            
            if ( _.includes(this.blocksOnPage, value.selector)) {
                  //  console.log('this is true' + value.selector)
              //  console.log(`this block is on the page ${block}`)

                    // create block on page
                    this.blocks[block].create({ scroll: this.smoothScroll, container: this.scrollWrapper })
                    // enable gutenberg for blocks on the page
            } else {
              //  console.log('no blocks on this page')
            }       
        }

       // if no blocks on the page, run createPages
        if (!this.blocksOnPage.length) {
         //   this.createPages()
        }
    }

    createPages() {

       // this.pages = {
        //    portfolio: new Portfolio()
      //  }
            
        // add a if statement for each page
    //    if ( this.body.classList.contains('our-portfolio') ) {
    //      this.pages.portfolio.create({ scroll: this.smoothScroll, container: this.scrollWrapper, header: this.header })
    //    } else {
    //      //  console.log('no pages here')
    //    }

    }

    addEventListeners() {
        // run resize every time page changes
        window.addEventListener('resize', this.onResize.bind(this))

       this.smoothScroll.scroll.on('scroll', this.header.isScrolling.bind(this))

        // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
        ScrollTrigger.addEventListener("refresh", () => this.smoothScroll.scroll.update());
    }
}

const website = new App()




